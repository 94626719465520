<template>
    <div
        :class="isShowedSidebar ? 'sidebar-opened' : 'sidebar-closed'"
        class="guidelines-wrap"
    >
        <div>
            <div class="guidelines-list">
                <h2> General guidelines </h2>
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    :class="{ active: activeIndex === index }"
                    class="guidelines-item"
                    @click="setActive(index)"
                >
                    {{ index + 1 }}.{{ item.title }}
                </div>
            </div>
            <div class="active-guideline">
                <div class="text-block">
                    <h2> {{ activeItem.title }} </h2>
                    <div
                        class="content"
                        v-html="activeItem.content"
                    />
                </div>
                <div class="buttons-block">
                    <div class="buttons">
                        <custom-button
                            v-if="activeIndex < items.length - 1"
                            default
                            type="button"
                            class="blue-button"
                            @click.native="next"
                        >
                            understood
                        </custom-button>
                        <custom-button
                            v-if="activeIndex > 0"
                            default
                            type="button"
                            class="back-button"
                            @click.native="back"
                        >
                            back
                        </custom-button>
                        <span
                            v-if="activeIndex > 0"
                            @click="toStart"
                        >
                            <Autorenew />
                            start over
                        </span>
                    </div>
                    <div class="counter">
                        {{ activeIndex + 1 }} / {{ items.length }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import items from '@/helpers/constants/guidelines/items'
import Autorenew from 'mdi-vue/Autorenew.vue'

export default {
    name: 'Guidelines',
    components: { Autorenew },
    data() {
        return {
            isShowedSidebar: true,
            activeIndex: 0
        }
    },
    computed: {
        items() {
            return items
        },
        activeItem() {
            return this.items[this.activeIndex]
        }
    },
    mounted() {
        this.$bus.$on('toggleSidebar', (val) => {
            this.isShowedSidebar = val
        })
    },
    beforeDestroy() {
        this.$bus.$off('toggleSidebar')
    },
    methods: {
        setActive(index) {
            this.activeIndex = index
        },
        next() {
            this.setActive(this.activeIndex + 1)
        },
        back() {
            this.setActive(this.activeIndex - 1)
        },
        toStart() {
            this.setActive(0)
        }
    }

}
</script>

<style scoped lang="scss">
.guidelines-wrap {
    margin-top: 64px;
    min-height: calc(100vh - 64px);
    background: #e5e5e5;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 60px;
    transition: padding .2s ease;
    &.sidebar-closed {
        padding-left: 10px;
    }
    & > div {
        display: flex;
        .active-guideline, .guidelines-list {
            background: #FFFFFF;
            border-radius: 4px;
            height: fit-content;
            margin-right: 15px;
            h2 {
                font-weight: bold;
                font-size: 20px;
                line-height: 25px;
                color: #1F2939;
            }
        }
        .active-guideline{
            .text-block {
                .content {
                    font-size: 16px;
                    line-height: 20px;
                    color: #1F2939;
                    padding: 10px 0;
                    margin: 20px 0;
                }
            }
            .text-block, .buttons-block {
                padding: 30px;
            }
            .buttons-block {
                border-top: 1px solid #D3D9E2;
                padding-top: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .buttons {
                    display: flex;
                    .back-button {
                        margin-left: 10px;
                    }
                    & > span {
                        cursor: pointer;
                        align-self: center;
                        margin-left: 10px;
                        padding: 12px 24px;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                }
                .counter {
                    color: #818181;
                }
            }
            .text-block {
                padding-bottom: 0;
            }
        }
        .guidelines-list{
            min-width: 350px;
            padding: 30px;
            .guidelines-item {
                font-size: 16px;
                line-height: 20px;
                color: #1F2939;
                padding: 10px 0;
                margin: 20px 0;
                cursor: pointer;
                position: relative;
                &:last-of-type {
                    margin-bottom: 0;
                }
                &.active {
                    font-weight: bold;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 0;
                        left: -30px;
                        background-color: #1976D2;
                        height: 100%;
                        width: 3px;
                    }
                }
            }
        }
    }
}
</style>
