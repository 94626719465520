export default [
    {
        title: 'Grammar, Spelling, Word Choice',
        content: 'All writing must feature correct English grammar and spelling, as well as proper word choice. There must be neither run-on sentences, nor typos in the content. All content must be proofread before submitting.'
    },
    {
        title: 'Content Flow',
        content: 'Content Flow All writing must feature correct English grammar and spelling, as well as proper word choice. There must be neither run-on sentences, nor typos in the content. All content must be proofread before submitting.'
    },
    {
        title: 'Concise & Comprehensive',
        content: 'Concise & Comprehensive All writing must feature correct English grammar and spelling, as well as proper word choice. There must be neither run-on sentences, nor typos in the content. All content must be proofread before submitting.'
    }
]
